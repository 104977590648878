import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'es6-promise/auto';

import App from 'next/app';

import '../static/styles/_variables.scss';
import '../static/styles/_mixins.scss';
import '../static/styles/normalize.scss';
import '../static/styles/articles.scss';
import '../static/styles/global.scss';
import '../static/styles/icomoon.scss';
import '../static/styles/optanon-ob.scss';
import '../static/styles/header.scss';
import '../static/styles/modal.scss';
import '../static/styles/one-page-scroll.scss';
import '../static/styles/bazaarvoice.scss';
import '../components/AdditionalReadingBlock/AdditionalReadingBlock.scss';
import '../components/AnnouncementBar/AnnouncementBar.scss';
import '../components/AppDownloadBlock/AppDownloadBlock.scss';
import '../components/ArticleBody/ArticleBody.scss';
import '../components/ArticleCategoryHeroBlock/ArticleCategoryHeroBlock.scss';
import '../components/ArticleHeroBlock/ArticleHeroBlock.scss';
import '../components/ArticleList/ArticleListBlock.scss';
import '../components/ArticleShare/ArticleShare.scss';
import '../components/ArticleTableBlock/ArticleTableBlock.scss';
import '../components/AwardsBlock/AwardsBlock.scss';
import '../components/Backdrop/Backdrop.scss';
import '../components/BiographyContent/BiographyContent.scss';
import '../components/BodyText/BodyText.scss';
import '../components/BrandGuaranteeBlock/BrandGuaranteeBlock.scss';
import '../components/Breadcrumbs/BreadCrumbs.scss';
import '../components/BrushConfigurator/BrushConfigurator.scss';
import '../components/Button/Button.scss';
import '../components/Layout/Layout.scss';
import '../components/ButtonTabsNavigation/ButtonTabsNavigation.scss';
import '../components/CharacterBlock/CharacterBlock.scss';
import '../components/CollectionTabs/CollectionTabsBlock.scss';
import '../components/CollectionTabs/CollectionTabsDropdownBlock.scss';
import '../components/ComparisonChartBlock/ComparisonChartBlock.scss';
import '../components/CongratulationsBlock/CongratulationsBlock.scss';
import '../components/ContactUsBlock/ContactUsBlock.scss';
import '../components/Content/Content.scss';
import '../components/Content/Content.scss';
import '../components/ContentDetailsBlock/ContentDetailsBlock.scss';
import '../components/ContentVideo/ContentVideo.scss';
import '../components/DesignByDentist/DesignedByDentist.scss';
import '../components/Disclaimer/Disclaimer.scss';
import '../components/DotNav/DotNav.scss';
import '../components/ErrorBlock/ErrorBlock.scss';
import '../components/Eyebrow/Eyebrow.scss';
import '../components/FAQBlock/FAQBlock.scss';
import '../components/FAQBlock/FAQQA.scss';
import '../components/FaqDetailsBlock/FaqDetailsBlock.scss';
import '../components/FeaturesTabBlock/FeaturesTabBlock.scss';
import '../components/Footer/Footer.scss';
import '../components/GalleryBlock/GalleryBlock.scss';
import '../components/Heading/Heading.scss';
import '../components/Icon/Icon.scss';
import '../components/Image/BackgroundImage/BackgroundImage.scss';
import '../components/Image/Img/Img.scss';
import '../components/InTheBoxBlock/InTheBoxBlock.scss';
import '../components/Janrain/components/CheckboxInput/CheckboxInput.scss';
import '../components/Janrain/components/DoubleOptInVerification/DoubleOptInVerification.scss';
import '../components/Janrain/components/EditProfile/EditProfile.scss';
import '../components/Janrain/components/FormBirthdate/FormBirthdate.scss';
import '../components/Janrain/components/FormGoalsCheckboxes/FormGoalsCheckboxes.scss';
import '../components/Janrain/components/FormInput/FormInput.scss';
import '../components/Janrain/components/FormSelect/FormSelect.scss';
import '../components/Janrain/components/ProductManagement/ProductManagement.scss';
import '../components/Janrain/components/RegisterProduct/RegisterProduct.scss';
import '../components/Janrain/components/SubmitButton/SubmitButton.scss';
import '../components/Janrain/components/ToggleInput/ToggleInput.scss';
import '../components/Janrain/components/UpdateBenefitPreferences/UpdateBenefitPreferences.scss';
import '../components/Janrain/Janrain/janrainForms/ChangePasswordForm/ChangePasswordForm.scss';
import '../components/Janrain/Janrain/janrainForms/ForgotPasswordForm/ForgotPasswordForm.scss';
import '../components/Janrain/Janrain/janrainForms/FormErrors/FormErrors.scss';
import '../components/Janrain/Janrain/janrainForms/LoginForm/LoginForm.scss';
import '../components/Janrain/Janrain/janrainForms/RegisterForm/RegisterForm.scss';
import '../components/Janrain/Janrain/janrainForms/RegisterProductForm/RegisterProductForm.scss';
import '../components/Janrain/Janrain/janrainForms/RegisterTypeCodeForm/RegisterTypeCodeForm.scss';
import '../components/Janrain/Janrain/janrainForms/ResetPasswordForm/ResetPasswordForm.scss';
import '../components/LanguageSelection/LanguageSelection.scss';
import '../components/LegacyArticleHeroBlock/LegacyArticleHeroBlock.scss';
import '../components/LegacyProductHighlightsBlock/LegacyProductHighlights.scss';
import '../components/LoginNavigationBlock/LoginNavigationBlock.scss';
import '../components/MainMenu/MainMenu.scss';
import '../components/MiniNavigation/MiniNavigation.scss';
import '../components/ObLink/ObLink.scss';
import '../components/OnePageScroll/OnePageScroll.scss';
import '../components/Paging/Paging.scss';
import '../components/PressQuoteBlock/PressQuote.scss';
import '../components/PressQuoteBlock/PressQuoteBlock.scss';
import '../components/ProductGridBlock/ProductGridBlock.scss';
import '../components/ProductHighlights/ProductHighlights.scss';
import '../components/ProductLegacyBody/ProductLegacyBody.scss';
import '../components/ProductOverlay/ProductOverlay.scss';
import '../components/ProductRecommender/ProductRecommender.scss';
import '../components/ProductsOverview/ProductOverview.scss';
import '../components/ProductsOverview/desktop/CollapsibleProductsOverview.scss';
import '../components/ProductsOverview/mobile/CarrouselProductsOverview.scss';
import '../components/ProductSubNav/ProductSubNav.scss';
import '../components/ProductVariantsRecommanderBlock/ProductVariantsRecommander.scss';
import '../components/ProfileBlock/ProfileBlock.scss';
import '../components/ProgressBar/ProgressBar.scss';
import '../components/Promo/Promo.scss';
import '../components/PypestreamChat/PypestreamChat.scss';
import '../components/RecentlyViewedProductsBlock/RecentlyViewedProductsBlock.scss';
import '../components/RichTextLink/RichTextLink.scss';
import '../components/SearchBar/SearchBar.scss';
import '../components/SearchBlock/SearchBlock.scss';
import '../components/SeeMoreButton/SeeMoreButton.scss';
import '../components/SeriesSelector/SeriesSelector.scss';
import '../components/ShowcaseBlock/ShowcaseBlock.scss';
import '../components/SideBySideContainer/SideBySideContainer.scss';
import '../components/SitemapBlock/SitemapBlock.scss';
import '../components/SitemapPage/SitemapPage.scss';
import '../components/SpotlightContentBlock/SpotlightContentBlock.scss';
import '../components/SpotlightContentVideo/SpotlightContentVideo.scss';
import '../components/SpotlightContestBlock/SpotlightContestBlock.scss';
import '../components/SpotlightContestThankYouBlock/SpotlightContestThankYouBlock.scss';
import '../components/SpotlightExperienceBlock/SpotlightExperienceBlock.scss';
import '../components/SpotlightPreorderBlock/SpotlightPreorderBlock.scss';
import '../components/SpotlightSlider/spotlight-custom.scss';
import '../components/SpotlightStickyBar/SpotlightStickyBar.scss';
import '../components/SpotlightWaitlistBlock/SpotlightWaitlistBlock.scss';
import '../components/StarRating/StarRating.scss';
import '../components/TabbedContent/TabbedContent.scss';
import '../components/ThreeColumnsUserQuoteBlock/ThreeColumnsUserQuoteBlock.scss';
import '../components/UnderlineTabsNavigation/UnderlineTabsNavigation.scss';
import '../components/UserQuoteBlock/UserQuoteBlock.scss';
import '../components/VideoPlayer/VideoPlayer.scss';
import '../components/YoutubeVideo/YoutubeVideo.scss';
import '../components/Zone/Zone.scss';

export default App;
